@import '@styles/mixins.sass'

.container
    width: 100%
    background-image: linear-gradient(135deg, var(--gradient-color), var(--gradient-color-2))
    &:hover
        .imgContainer
            transform: scale(1.2)
        .bgContainer
            background-color: transparent
        // .circleYellow
        //     animation: circleGrowUp .5s ease forwards
        //     @include for-tablet
        //         animation: circleGrowUpTablet .5s ease forwards
        // .circleBlue
        //     animation: circleGrowUp .5s ease forwards
        //     @include for-tablet
        //         animation: circleGrowUpTablet .5s ease forwards
        // .blurYellow
        //     animation: blurGrowUp .5s ease forwards
        //     @include for-tablet
        //         animation: blurGrowUpTablet .5s ease forwards
        // .blurBlue
        //     animation: blurGrowUp .5s ease forwards
        //     @include for-tablet
        //         animation: blurGrowUpTablet .5s ease forwards

// .yellow
//     transition: all 0.3s ease
//     &:hover
//         border: 1px solid var(--accent-color)

// .blue
//     transition: all 0.3s ease
//     &:hover
//         border: 1px solid var(--accent-color-2)

.bgContainer
    background-color: var(--primary-color)
    padding: 35px 40px
    width: 100%
    height: 100%
    display: flex
    align-items: center
    justify-content: space-between
    transition: all .5s ease
    @include for-small-desktop
        padding: 35px 30px
    @include for-tablet
        padding: 85px 20px 35px
        flex-direction: column-reverse
        align-items: flex-start
        justify-content: flex-start
        position: relative

.contentContainer
    max-width: 50%
    @include for-small-desktop
        max-width: 65%
    @include for-tablet
        max-width: 100%
        z-index: 2

.descriptionContainer
    margin: 15px 0 25px
    p
        color: var(--text-color)
        font: var(--font-md20)
        @include for-small-desktop
            font: var(--font-md18)
        @include for-tablet-portrait
            font: var(--font-md17)

.btnLinkContainer
    max-width: 290px
    @include for-small-desktop
        max-width: 220px
    @include for-tablet-portrait
        max-width: 100%

.animationContainer
    width: 100%
    height: 100%
    position: relative
    @include for-tablet
        position: absolute
        z-index: 1

.imgContainer
    position: relative
    transition: all .5s ease
    right: -35px
    height: 90%
    min-height: 180px
    width: 90%
    z-index: 4
    @include for-small-desktop
        right: -5px
        height: 100%
        min-height: 170px
        width: 130%
    @include for-tablet
        min-height: auto
        width: 100%
        height: 130px
        right: 0
        top: -30px
    @include for-tablet-portrait
        height: 120px
        width: 90%
        top: -20px

// .circleYellow
//     transform: translate(-50%, -50%)
//     transition: width 0.5s, height 0.5s
//     top: 28%
//     left: 50%
//     width: 0
//     height: 0
//     animation: circleGrowDown 0.5s ease forwards
//     position: absolute
//     background-color: var(--accent-color)
//     z-index: 1
//     border-radius: 50%
//     transition: all 0.5s ease
//     @include for-small-desktop
//         top: 35%
//         left: 60%
//     @include for-tablet
//         top: 10px
//         left: 45%
//     @include for-tablet-portrait
//         top: 15px
//         left: 40%
//     @include for-phone

// .circleBlue
//     transform: translate(-50%, -50%)
//     transition: width 0.5s, height 0.5s
//     top: 28%
//     left: 33%
//     width: 0
//     height: 0
//     animation: circleGrowDown 0.5s ease forwards
//     position: absolute
//     transition: all 0.5s ease
//     background-color: var(--accent-color-2)
//     z-index: 1
//     border-radius: 50%
//     @include for-small-desktop
//         top: 45%
//         left: 45%
//     @include for-tablet
//         top: 10px   
//         left: 35%
//     @include for-tablet-portrait
//         top: 15px
//         left: 30%

// .blurYellow
//     animation: blurGrowDown 0.5s ease forwards
//     width: 0
//     height: 0
//     position: absolute
//     top: 28%
//     left: 50%
//     transform: translate(-50%, -50%)
//     background-color: var(--accent-color)
//     z-index: 3
//     border-radius: 50%
//     filter: blur(20px)
//     @include for-small-desktop
//         top: 35%
//         left: 60%
//     @include for-tablet
//         top: 10px
//         left: 45%
//     @include for-phone
//         top: 15px
//         left: 40%

// .blurBlue
//     animation: blurGrowDown 0.5s ease forwards
//     width: 0
//     height: 0
//     position: absolute
//     top: 28%
//     left: 33%
//     transform: translate(-50%, -50%)
//     background-color: var(--accent-color-2)
//     z-index: 3
//     border-radius: 50%
//     filter: blur(20px)
//     @include for-small-desktop
//         top: 40%
//         left: 45%
//     @include for-tablet
//         top: 10px   
//         left: 35%
//     @include for-phone
//         top: 15px
//         left: 30%

// @keyframes circleGrowUp
//     0%
//         width: 0
//         height: 0
//     100%
//         width: 160px
//         height: 160px

// @keyframes circleGrowUpTablet
//     0%
//         width: 0
//         height: 0
//     100%
//         width: 110px
//         height: 110px
        

// @keyframes circleGrowDown
//     0%
//         width: 160px
//         height: 160px
//     100%
//         width: 0
//         height: 0

// @keyframes circleGrowDownTablet
//     0%
//         width: 100px
//         height: 100px
//     100%
//         width: 0
//         height: 0

// @keyframes blurGrowUp
//     0%
//         width: 0
//         height: 0
//     100%
//         width: 130px
//         height: 130px

// @keyframes blurGrowUpTablet
//     0%
//         width: 0
//         height: 0
//     100%
//         width: 100px
//         height: 100px

// @keyframes blurGrowDown
//     0%
//         width: 130px
//         height: 130px
//     100%
//         width: 0
//         height: 0

// @keyframes blurGrowDownTablet
//     0%
//         width: 100px
//         height: 100px
//     100%
//         width: 0
//         height: 0

        