@import '@styles/mixins.sass'

.container
    height: 100%
    background-color: var(--primary-color)
    padding: 100px 140px
    @include for-small-desktop
        padding: 90px 100px
    @include for-tablet
        padding: 70px 60px
    @include for-tablet-portrait
        padding: 55px 40px
    @include for-phone
        padding: 45px 15px

.topContainer
    display: flex
    flex-direction: column
    align-items: center
    margin-bottom: 60px
    @include for-small-desktop
        margin-bottom: 50px
    @include for-tablet
        margin-bottom: 40px
    @include for-tablet-portrait
        margin-bottom: 30px
    @include for-phone
        margin-bottom: 20px

.headerContainer
    h3
        font: var(--font-lg)
        font-weight: 700
        @include for-small-desktop
            font: var(--font-lg60)
            font-weight: 700
        @include for-tablet-portrait
            font: var(--font-lg50)
            font-weight: 700
        @include for-phone
            font: var(--font-lg38)
            font-weight: 700

.descriptionContainer
    margin-top: 45px
    @include for-small-desktop
        margin-top: 35px
    @include for-tablet
        margin-top: 20px
    p
        color: var(--text-color)
        font: var(--font-md20)
        @include for-small-desktop
            font: var(--font-md19)
        @include for-tablet-portrait
            font: var(--font-md18)
        @include for-phone
            font: var(--font-md17)