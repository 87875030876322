@import '@styles/mixins.sass'

.textTwoColumns
    display: flex
    align-items: flex-start
    justify-content: space-between
    @include for-tablet-portrait
        flex-direction: column

.column
    width: 50%
    margin-right: 40px
    @include for-tablet-portrait
        width: 100%
        margin-right: 0
    &:last-child
        margin-right: 0
        @include for-phone
            .headerContainer
                margin-top: 25px

.paragraphContainer
    margin-bottom: 50px
    @include for-tablet
        margin-bottom: 40px
    @include for-phone
        margin-bottom: 30px
    &:last-child
        margin-bottom: 0

.headerContainer
    margin-bottom: 20px
    @include for-phone
        margin-bottom: 15px
        max-width: 80%

.textContainer
    p
        text-indent: 20px
        margin: 25px 0
        color: var(--text-color)
        font: var(--font-md20)
        @include for-small-desktop
            font: var(--font-md19)
        @include for-tablet
            font: var(--font-md18)
            margin: 20px 0
        @include for-phone
            font: var(--font-md17)
            margin: 15px 0
            &:first-child
                margin-top: 0
    ul, ol
        margin-left: 40px
        li
            color: var(--text-color)
            font: var(--font-md20)
            margin-bottom: 7px
            @include for-small-desktop
                font: var(--font-md19)
            @include for-tablet
                font: var(--font-md18)
            @include for-phone
                font: var(--font-md17)

.imgContainer
    position: relative
    width: 50%
    height: 380px
    margin: 10px auto 10px auto
    @include for-small-desktop
        height: 300px
    @include for-tablet
        height: 250px
    @include for-tablet-portrait
        // height: 200px
    img
        width: 100%
        height: 100%
        object-fit: cover
        object-position: center

.imgBgCover
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    z-index: 1
    background: var(--bg-color)
    opacity: 0.5