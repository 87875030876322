@import '@styles/mixins.sass'

.container
    z-index: -1
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0

.imgContainer
    width: 100%
    height: 100%
    position: relative
    img
        width: 100%
        height: 100%
        object-fit: cover