@import '@styles/mixins.sass'

.container
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    margin: 0 30px
    @include for-small-desktop
        margin: 0 20px
    @include for-tablet
        margin: 0 10px
    @include for-tablet-portrait
        display: none