@import '@styles/mixins.sass'

.container
    padding: 213px 0 20px
    background-color: var(--primary-color)
    width: 33.33%
    position: relative
    @include for-small-desktop
        padding: 170px 0 20px
    @include for-tablet
        padding: 150px 0 20px
    @include for-tablet-portrait
        padding: 140px 0 20px
        width: 480px
    @include for-phone
        padding: 130px 0 20px
        width: 100%
    &:nth-child(2)
        margin: 0 60px
        @include for-small-desktop
            margin: 0 40px
        @include for-tablet
            margin: 0 20px
        @include for-tablet-portrait
            margin: 60px 0
    &:hover
        .elipsBlur
            animation: elipsBlurUp 0.3s ease-in-out forwards
            width: 35%
            height: 35%
            opacity: 1
        .line
            animation: lineGrowUp 0.3s ease-in-out forwards
            width: 100% 

.imgContainer
    display: flex
    align-items: flex-end
    justify-content: center
    position: absolute
    width: 100%
    height: 270px
    top: -100px
    @include for-small-desktop
        height: 220px
        top: -80px
    @include for-tablet
        height: 190px
        top: -60px
    @include for-tablet-portrait
        height: 170px
        top: -45px
    @include for-phone
        height: 160px

.animationContainer
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    align-items: center
    justify-content: flex-end

.elipsBlur
    width: 0
    height: 0
    animation: elipsBlurDown 0.3s ease-in-out forwards
    background-color: var(--accent-color)
    border-radius: 50%
    filter: blur(50px)

.elipsBlurBlue
    @extend .elipsBlur
    background-color: var(--accent-color-2)

.line
    animation: lineGrowDown 0.3s ease-in-out forwards
    width: 0
    opacity: 0
    height: 35px
    background-color: var(--accent-color)
    margin: 30px 0
    @include for-small-desktop
        margin: 20px 0
        height: 30px
    @include for-tablet
        height: 25px
    @include for-tablet-portrait
        margin: 15px 0

.lineBlue
    @extend .line
    background-color: var(--accent-color-2)

.textContainer
    padding: 0 30px
    @include for-tablet
        padding: 0 20px
    @include for-tablet-portrait
        padding: 0 15px

.titleContainer
    margin-bottom: 15px
    @include for-small-desktop
        margin-bottom: 10px

.descriptionContainer
    p
        color: var(--text-color)
        font: var(--font-md20)
        max-width: 515px
        margin-bottom: 30px
        overflow-wrap: break-word
        white-space: pre-wrap
        @include for-small-desktop
            font: var(--font-md18)
            max-width: 400px
            margin-bottom: 20px
        @include for-tablet
            font: var(--font-md17)
            max-width: 300px
            margin-bottom: 15px
        @include for-tablet-portrait
            max-width: 100%

@keyframes lineGrowUp
    0%
        width: 0
        opacity: 0
    100%
        opacity: 1
        width: 100%

@keyframes lineGrowDown
    0%
        opacity: 1
        width: 100%
    100%
        opacity: 0
        width: 0

@keyframes elipsBlurUp
    0%
        width: 0
        height: 0
    100%
        width: 35%
        height: 35%

@keyframes elipsBlurDown
    0%
        width: 35%
        height: 35%
    100%
        width: 0
        height: 0