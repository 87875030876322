@import '@styles/mixins.sass'
@import '@styles/colors.sass'

.accordionContainer
    display: flex
    & .accordionSummary
         display: flex
         justify-content: space-between
         align-items: center
         padding: 15px 20px
         background: var(--primary-color)

         & .accordionBtn
            width: 50px
            height: 30px
            background: transparent
            border: none
            & .accordionBtnImg
                width: 30px
                height: 30px

    & .accordionContent
        & p
            line-height: 1.6
            color: var(--text-color)
            opacity: .8
            