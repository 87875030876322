@import '@styles/mixins.sass'

.header
    text-transform: uppercase
    font: var(--font-xl)
    font-weight: 700
    color: var(--text-color)
    white-space: pre-wrap
    @include for-small-desktop
        font: var(--font-lg)
        font-weight: 700
    @include for-tablet
        font: var(--font-lg60)
        font-weight: 700
    @include for-tablet-portrait
        font: var(--font-lg50)
        font-weight: 700
    @include for-phone
        font: var(--font-lg38)
        font-weight: 700

.yellow
    span
        color: var(--accent-color)

.blue
    span
        color: var(--accent-color-2)

.blueYellow
    span
        color: var(--accent-color)
        &:first-child
            color: var(--accent-color-2)
