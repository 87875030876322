@import '@styles/mixins.sass'
    
.arrow
    position: absolute
    top: 50%
    transform: translateY(-50%)
    width: 40px
    height: 40px
    background-color: transparent
    border: none
    outline: none
    cursor: pointer
    @include for-tablet
        width: 30px
        height: 30px
    svg
        width: 25px
        height: 50px
        @include for-tablet
            width: 20px
            height: 40px
    path
        stroke: #fff
        transition: all 0.3s ease-in-out

    &:hover
        path
            stroke: var(--accent-color)
