@import '@styles/mixins.sass'

.container
    position: relative //for noiseBg
    background-color: var(--bg-color)
    padding: 120px 140px
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: center
    @include for-small-desktop
        padding: 80px 100px
    @include for-tablet
        padding: 60px 80px
    @include for-tablet-portrait
        padding: 55px 40px
    @include for-phone
        padding: 40px 15px

.headerContainer
    z-index: 1

.servicesContainer
    z-index: 1
    width: 100%
    margin-top: 60px
    display: grid
    grid-template-columns: repeat(2, 1fr)
    grid-gap: 60px 80px
    @include for-small-desktop
        margin-top: 50px
        grid-gap: 40px 50px
    @include for-tablet
        margin-top: 100px
        grid-gap: 100px 50px
    @include for-tablet-portrait
        grid-gap: 85px 30px
    @include for-phone
        grid-template-columns: 1fr

.lastItemContainer
    z-index: 1
    overflow: hidden
    width: 100%
    margin-top: 60px
    display: flex
    @include for-small-desktop
        margin-top: 40px
    @include for-tablet
        margin-top: 50px
    @include for-tablet
        overflow: visible
        margin-top: 100px
    @include for-tablet-portrait
        margin-top: 85px