@import '@styles/mixins.sass'

.container
    position: relative //for noiseBg
    padding: 120px 140px
    @include for-small-desktop
        padding: 90px 100px
    @include for-tablet
        padding: 70px 60px
    @include for-tablet-portrait
        padding: 55px 40px
    @include for-phone
        padding: 45px 15px 35px

.contentContainer
    display: flex
    justify-content: space-between
    margin-top: 150px
    @include for-small-desktop
        margin-top: 120px
    @include for-tablet
        margin-top: 100px
    @include for-tablet-portrait
        width: 100%
        flex-direction: column
        align-items: center
        justify-content: center
        margin-top: 80px
    @include for-phone
        margin-top: 60px
    