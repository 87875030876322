@import '@styles/mixins.sass'

.container
    margin-bottom: 20px
    @include for-small-desktop
        margin-bottom: 10px

.contentContainer
    background-color: var(--primary-color)
    margin: 0 10px
    padding: 25px 30px 35px
    @include for-small-desktop
        margin: 0 5px
        padding: 25px 30px 30px
    @include for-tablet
        padding: 20px 25px 25px
    @include for-phone
        padding: 15px 15px 20px

.topContainer
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 20px
    @include for-tablet
        flex-direction: column
        align-items: flex-start
    @include for-tablet-portrait
        flex-direction: row
        align-items: center
    @include for-phone
        flex-direction: column
        align-items: flex-start
    @include for-phone
        margin-bottom: 15px

.topLeftContainer
    display: flex
    align-items: center
    @include for-tablet
        margin-bottom: 15px
    @include for-tablet-portrait
        margin-bottom: 0
    @include for-phone
        margin-bottom: 10px

.imgContainer
    position: relative
    height: 75px
    width: 75px
    border-radius: 50%
    overflow: hidden
    margin-right: 15px
    @include for-small-desktop
        height: 65px
        width: 65px
        margin-right: 10px
    @include for-tablet
        height: 60px
        width: 60px
        margin-right: 10px

.nameAndStarsContainer
    display: flex
    flex-direction: column
    justify-content: center
    align-items: flex-start

.nameContainer
    margin-bottom: 3px
    p
        color: var(--text-color)
        font: var(--font-md22)
        text-align: center
        @include for-small-desktop
            font: var(--font-md20)
        @include for-phone
            font: var(--font-md18)

.starsContainer
    display: flex
    align-items: flex-start
    justify-content: flex-start
    p
        margin-left: 5px
        color: var(--text-color)
        font: var(--font-md22)
        text-align: center
        @include for-small-desktop
            font: var(--font-md20)
            margin-left: 3px
        @include for-phone
            font: var(--font-md18)
            margin-left: 2px

.iconContainer
    width: 20px
    height: 20px
    margin-right: 5px
    @include for-small-desktop
        width: 18px
        height: 18px
        margin-right: 4px
    @include for-phone
        width: 16px
        height: 16px
        margin-right: 3px
    svg
        width: 100%
        height: 100%

.topRightContainer
    display: flex
    align-items: flex-end
    justify-content: flex-end

.fromContainer, .toContainer
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    p
        color: var(--light-accent-color)
        margin-bottom: 8px
        @include for-small-desktop
            margin-bottom: 6px
        @include for-phone
            font: var(--font-xs)
            margin-bottom: 4px

.flagImgContainer
    position: relative
    height: 40px
    width: 40px
    border-radius: 50%  
    overflow: hidden
    @include for-small-desktop
        height: 35px
        width: 35px
    @include for-phone
        height: 30px
        width: 30px
    img
        height: 100%
        width: 100%
        object-fit: cover

.line
    height: 1px
    width: 80px
    margin: 0 10px 20px
    background-color: var(--light-accent-color) 
    @include for-small-desktop
        width: 65px
        margin: 0 5px 15px
    @include for-phone
        width: 55px
        margin: 0 5px 14px


// .contentContainer
//     height: 335px
//     width: 100%
//     padding: 90px 20px 20px
//     position: relative
//     display: flex
//     flex-direction: column
//     justify-content: flex-start
//     align-items: flex-start
//     @include for-small-desktop
//         height: 325px
//         padding: 80px 20px 20px
//     @include for-tablet-portrait
//         height: 310px
//         padding: 70px 10px 20px

.commentContainer
    width: 100%
    overflow-x: auto
    height: 80px
    padding-right: 10px
    p
        color: var(--text-color)
        font: var(--font-md20)
        line-height: 25px
        @include for-small-desktop
            font: var(--font-md18)
            line-height: 22px
        @include for-tablet-portrait
            font: var(--font-md18)
        @include for-phone
            font: var(--font-md16)
            line-height: 19px