@import '@styles/mixins.sass'

.container
    position: relative //for bgNoise
    padding: 0 140px 80px //0 140 120
    @include for-small-desktop
        padding: 0 100px 60px //0 100 80
    @include for-tablet
        padding: 0 80px 40px //0 80 60
    @include for-tablet-portrait
        padding: 0 40px 55px //0 40 55
    @include for-phone
        padding: 0 15px 55px //0 15 40

.containerPadding
    position: relative //for bgNoise
    padding: 100px 140px 60px
    @include for-small-desktop
        padding: 90px 100px 50px
    @include for-tablet
        padding: 70px 60px 40px
    @include for-tablet-portrait
        padding: 55px 40px 40px
    @include for-phone
        padding: 45px 15px 40px

.headerContainer
    margin-bottom: 60px
    @include for-small-desktop
        margin-bottom: 40px
    @include for-tablet-portrait
        margin-bottom: 30px

.sliderContainer
    display: flex
    justify-content: center