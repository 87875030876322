@import '@styles/mixins.sass'

.container
    margin: 0 20px
    background-color: var(--bg-color)
    @include for-small-desktop
        margin: 0 15px
    @include for-tablet-portrait
        margin: 0 10px
    @include for-phone
        margin: 0 5px

.contentContainer
    padding: 30px
    @include for-small-desktop
        padding: 25px
    @include for-phone
        padding: 20px

.headerContainer
    margin-bottom: 25px
    @include for-tablet
        margin-bottom: 20px
    p
        transition: color 0.3s ease-in-out

.imgContainer
    position: relative
    height: 450px
    width: 100%
    margin-bottom: 15px
    @include for-phone
        height: 200px
        margin-bottom: 10px

.dateContainer
    width: max-content
    margin: 0 0 28px
    display: flex
    align-items: center
    @include for-tablet
        margin: 0 0 20px
    @include for-phone
        // margin: 8px 0 10px
    p
        color: var(--primary-light-color)
        font: var(--font-sm)
        @include for-phone
            font: var(--font-xs)

.iconContainer
    width: 25px
    height: 25px
    margin-right: 10px
    @include for-tablet-portrait
        width: 20px
        height: 20px
        margin-right: 8px
    svg
        width: 100%
        height: 100%
        path
            fill: var(--primary-light-color)
            
.description
    margin-top: 20px
    margin-bottom: 25px
    color: var(--text-color)
    font: var(--font-sm)
    @include for-small-desktop
        font: var(--font-md18)
    @include for-tablet
        margin-bottom: 20px
    @include for-phone
        font: var(--font-md17)

.linkContainer
    cursor: pointer
    display: flex
    align-items: center
    justify-content: flex-end
    &:hover
        p
            color: var(--accent-color)
        .iconLinkContainer
            svg
                path
                    fill: var(--accent-color)
    p
        color: var(--primary-light-color)
        font: var(--font-md16)
        transition: color 0.3s ease-in-out
        @include for-tablet-portrait
            font: var(--font-sm)

.iconLinkContainer
    width: 12px
    height: 12px
    margin-left: 5px
    @include for-tablet-portrait
        width: 10px
        height: 12px
        margin-left: 4px
    svg
        width: 100%
        height: 100%
        path
            fill: var(--primary-light-color)
            transition: fill 0.3s ease-in-out