@import '@styles/mixins.sass'

.container
    display: flex
    flex-direction: column
    margin: 0 auto
    gap: 10px
    padding: 105px 200px 120px
    @include for-small-desktop
        padding: 0 100px 80px
    @include for-tablet
        padding: 0 80px 60px
    @include for-tablet-portrait
        padding: 0 40px 55px
    @include for-phone
        padding: 0 15px 40px