@mixin for-phone
    @media (max-width: 600px)
        @content

@mixin for-tablet-portrait
    @media (max-width: 900px)
        @content

@mixin for-tablet
    @media (max-width: 1200px)
        @content

@mixin for-small-desktop
    @media (max-width: 1536px)
        @content
