@import '@styles/mixins.sass'

.container
    position: relative

.bgImageCoverContainer
    position: absolute
    top: 0
    left: 0
    bottom: 0
    right: 0
    background: linear-gradient(180deg, #121212ce 100%, #21212147 0)
    z-index: 2

.bgImageContainer
    position: absolute
    top: 0
    left: 0
    bottom: 0
    right: 0
    z-index: 1
    img
        height: 100%
        width: 100%
        object-fit: cover


.contentContainer
    overflow: hidden
    position: relative
    display: flex
    align-items: flex-start
    justify-content: flex-start
    height: 100%
    min-height: 100vh
    width: 100%
    display: flex
    align-items: center
    justify-content: space-between
    background-color: transparent
    padding-left: 140px
    @include for-small-desktop
        padding-left: 100px
    @include for-tablet
        padding-left: 60px
    @include for-tablet-portrait
        padding-left: 40px
    @include for-tablet-portrait
        align-items: flex-start
    @include for-phone
        padding-left: 25px

.leftContainer
    margin: 120px 180px 160px 0
    display: flex
    flex-direction: column
    z-index: 3
    @include for-tablet
        margin: 150px 0 100px 0
    @include for-phone
        margin: 110px 0 50px 0     
    h1
        font: var(--font-xl)
        font-weight: 700
        text-transform: uppercase
        max-width: 700px
        color: var(--text-color)
        margin-bottom: 50px
        @include for-small-desktop
            font-size: 70px
            line-height: 76px
            max-width: 500px  
        @include for-tablet
            font-size: 60px
            line-height: 66px
            margin-bottom: 30px
        @include for-tablet-portrait  
            min-width: 590px   
        @include for-phone
            font-size: 44px
            line-height: 48px
            margin-bottom: 20px
            min-width: auto
            max-width: 100%
    p
        font-size: 28px
        line-height: 32px
        font-weight: 500
        color: var(--text-color)
        margin-bottom: 120px
        @include for-small-desktop
            font: var(--font-md22)
            margin-bottom: 60px
        @include for-tablet-portrait
            font: var(--font-md20)
        @include for-phone
            font: var(--font-md19)
            margin-bottom: 40px
            max-width: 58%

.textContainer
    .blue
        color: var(--accent-color-2)
    .yellow
        color: var(--accent-color)
    @include for-tablet-portrait
        position: absolute
        

.btnContainer
    max-width: 260px
    @include for-phone
        max-width: 210px

.rightContainer
    display: flex
    align-items: flex-end
    height: 100%
    min-height: 100vh
    width: 40%
    padding: 100px 0 0
    background-color: transparent
    @include for-tablet-portrait
        padding: 450px 0 0
    @include for-phone
        padding: 450px 0 0

.imgContainer
    position: relative
    bottom: -4px
    left: -150px
    z-index: 3
    @include for-tablet
        left: -100px
    @include for-tablet-portrait
        left: -150px
    @include for-phone
        left: -130px
    img
        height: calc(100vh - 110px)
        min-height: 782px
        object-fit: cover
        @include for-small-desktop
            height: calc(100vh - 120px)
            min-height: 710px
        @include for-tablet
            height: calc(100vh - 170px)
            min-height: 555px
        @include for-tablet-portrait
            height: calc(100vh - 500px)
            min-height: 580px
        @include for-phone
            height: calc(100vh - 480px)
            min-height: 390px

.nextScreenBtnContainer
    z-index: 3
    position: absolute
    left: 48.5%
    bottom: 50px
    cursor: pointer
    @include for-tablet-portrait
        display: none
    div
        svg
            width: 50px
            height: 75px
            @include for-tablet
                width: 40px
                height: 60px

.nextScreenBtnContainerMob
    z-index: 3
    display: none
    position: absolute
    left: 15%
    bottom: 200px
    @include for-phone
        display: block
        bottom: 100px
    svg
        width: 110px
        height: 110px
        @include for-phone
            width: 80px
            height: 80px
    p
        padding-top: 15px
        color: var(--text-color)
        font: var(--font-md20)

